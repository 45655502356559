import React from 'react'
import Layout from 'components/Layout'
import Container from 'components/Container'
import { graphql } from 'gatsby'

export default function LegalNotice({ data: { site } }) {
  return (
    <Layout site={site}>
      <Container>
        <h1>Attributions</h1>
        {/* tree from freepik
        budding/sprout from Freepik
        seed from Smashicons */}
        <div>
          Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> and <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
  }
`
